var pusher = new Pusher('149958b5270ed1fa1582', {
    encrypted: true
});

var channel = pusher.subscribe('iri_zip');

channel.bind('zip_complete', function(data) {
    var id = data.id;
    var message = data.message;
    var title = data.title;

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "0",
        "hideDuration": "0",
        "timeOut": "0",
        "extendedTimeOut": "0",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    toastr.info(message, title);

    $('#hierarchy' + id).toggleClass("disabled").prop('disabled', false);
    $('#zip' + id).toggleClass("disabled").prop('disabled', false);
    $('#link' + id).toggleClass("disabled").prop('disabled', false);
});